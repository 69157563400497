@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --teal-50: 166.2 76.5% 96.7%;
    --teal-100: 167.2 85.5% 89.2%;
    --teal-200: 168.4 83.8% 78.2%;
    --teal-300: 170.6 76.9% 64.3%;
    --teal-400: 172.5 66% 50.4%;
    --teal-500: 173.4 80.4% 40%;
    --teal-600: 174.7 83.9% 31.6%;
    --teal-700: 175.3 77.4% 26.1%;
    --teal-800: 176.1 69.4% 21.8%;
    --teal-900: 175.9 60.8% 19%;
    --teal-950: 178.6 84.3% 10%;

    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;

    --chart-2: 173 58% 39%;

    --chart-3: 197 37% 24%;

    --chart-4: 43 74% 66%;

    --chart-5: 27 87% 67%;
  }

  .dark {
    --teal-50: 166.2 76.5% 96.7%;
    --teal-100: 167.2 85.5% 89.2%;
    --teal-200: 168.4 83.8% 78.2%;
    --teal-300: 170.6 76.9% 64.3%;
    --teal-400: 172.5 66% 50.4%;
    --teal-500: 173.4 80.4% 40%;
    --teal-600: 174.7 83.9% 31.6%;
    --teal-700: 175.3 77.4% 26.1%;
    --teal-800: 176.1 69.4% 21.8%;
    --teal-900: 175.9 60.8% 19%;
    --teal-950: 178.6 84.3% 10%;

    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --ring: 212.7 26.8% 83.9%;

    --radius: 0.5rem;

    --chart-1: 220 70% 50%;

    --chart-2: 160 60% 45%;

    --chart-3: 30 80% 55%;

    --chart-4: 280 65% 60%;

    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

@layer utilities {
  .striped div:nth-child(even) {
    @apply bg-gray-100;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-warning {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 20px,
    #fafafa 20px,
    #fafafa 40px
  );
}
